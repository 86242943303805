import locales from './locales.json'
import moment from 'moment/min/moment-with-locales';

console.log(moment.locales().join(", "))

//const locales = ["en-GB", "eu-ES"];
const formats = [
    { label: "Full", format: "full", moment: "LLLL" },
    { label: "Long", format: "long", moment: "LLL" },
    { label: "Medium", format: "medium", moment: "LL" },
    { label: "Short", format: "short", moment: "L" },
];

const input = document.getElementById("datetime-input");
const tableBody = document.getElementById("tablebody");

input.value = new Date().toISOString().slice(0, 10);

// const fillHeader = () => {
formats.forEach((f) => {
    const formattedCell = document.createElement("th")
    formattedCell.innerText = f.label
    document.getElementById("tableheader").appendChild(formattedCell)
})

// }
const showTableData = () => {
    let dateString = input.value;
    try {
        const date = new Date(dateString);
        tableBody.innerHTML = "";
        Object.entries(locales).forEach((locale) => {
            const impl = document.querySelector('input[name="format-implementation"]:checked').value
            if (impl === "browser") {
                tableBody.appendChild(writeJsFormat(locale, date))
            } else {
                const row = writeMomentFormat(locale, date)
                if (row != null) {
                    tableBody.appendChild(writeMomentFormat(locale, date))
                }
            }
        })

        console.log(date)

    } catch (error) {
        console.error(error)
        alert("Error!");
    }
}

const writeMomentFormat = (locale, date) => {
    const m = moment(date)
    const row = document.createElement("tr")
    const localeCell = document.createElement("td")
    const ml = m.locale(locale[0])
    localeCell.innerText = locale[1];
    row.appendChild(localeCell)
    if (!locale[0].toLowerCase().startsWith(ml.locale())) {
        console.log("no locale for ", locale, ml.locale())
        formats.forEach((f) => {
            const formattedCell = document.createElement("td")
            formattedCell.innerText = '';
            row.appendChild(formattedCell)
        })
        return row;
    }
    formats.forEach((f) => {
        const formattedCell = document.createElement("td")
        formattedCell.innerText = ml.format(f.moment);
        row.appendChild(formattedCell)
    })
    return row;
}
const writeJsFormat = (locale, date) => {
    const row = document.createElement("tr")
    const localeCell = document.createElement("td")
    localeCell.innerText = locale[1];
    row.appendChild(localeCell)
    let error
    formats.forEach((f) => {
        const formattedCell = document.createElement("td")
        try {
            if (Intl.DateTimeFormat.supportedLocalesOf(locale[0]).length === 0) {
                error = "no locale for " + locale[0]
            } else {
                formattedCell.innerText = date.toLocaleString(locale[0], { dateStyle: f.format });
            }
        } catch (error) {
            console.error(error)
        }
        row.appendChild(formattedCell)
    })
    if (error != null) {
        console.error(error)
    }
    return row;
}


document.querySelector("form").addEventListener("change", showTableData);
showTableData();